import { Component, OnInit } from '@angular/core';
import { AuthController } from 'src/app/controllers';
import { AccountController } from 'src/app/controllers/account.controller';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss']
})
export class AccountHeaderComponent implements OnInit {

  get user() {
    return this.auth.user;
  }

  constructor(
    private auth: AuthController,
    protected account: AccountController,
  ) { }

  ngOnInit(): void {
  }

}
