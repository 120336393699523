import { Component, OnInit } from '@angular/core';
import { AuthController, PaymentController, SettingsController } from 'src/app/controllers';
import { OrderController } from 'src/app/controllers/order.controller';
import { CustomerAmount, CustomerPayment, MemberTransaction, Order, User } from 'src/app/models';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';

declare var Stripe: any;
const stripe = Stripe(environment.stripeKey);

function hashAmount(amount: number, secretKey: string) {
  const plainText = amount.toString();
  const hash = crypto.HmacSHA256(plainText, secretKey).toString(crypto.enc.Hex);
  return hash;
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  cart: Order;
  showPayment: boolean;
  orderReceived: boolean;
  updateCart: boolean;
  loading: boolean;
  intending: boolean;
  errorMessage = "";

  user: User = {...this.auth.user};


  get master() {
    return this.settings.master;
  }

  constructor(
    private auth: AuthController,
    private settings: SettingsController,
    private payments: PaymentController,
    private orders: OrderController,
  ) { }

  ngOnInit(): void {
    this.orders.getCart().subscribe(orders => {
      this.cart = orders[0];
      console.log("got cart: ", this.cart, orders);
    });
    console.log("got user: ", this.user, this.user.name);
  }

  commalize(n: any) {
    if (!n) { return '0.00'; }
    const digits = (Number(n)).toFixed(2).split('').reverse();
    const decimals = digits.splice(0, 3);
    for (let i = 0, l = digits.length; i < l; i++) { if (i > 0 && i % 3 === 0) { digits[i] += ','; } }
    return decimals.concat(digits).reverse().join('');
  }

  async update() {
    this.orders.updateCart(this.cart).then(() => this.updateCart = false);
  }

  removeProduct(slug: string) {
    this.cart.products = this.cart.products.filter(product => product.slug !== slug);
    this.updateCart = true;
  }


  async initPayment() {
    this.intending = true;
    if (this.updateCart) {
      await this.update();
    }
    try {
      const {total} = this.cart;
      const {cid,uid} = this.user;
      const totalCents = Number(total.toFixed(2)) * 100;
      const certified = "certificate";
      const hash = hashAmount(totalCents, certified + this.master.key);
      const amount: CustomerAmount = {uid, certified, total: totalCents, hash, cid};
      this.payments.addCredit(amount).subscribe(async response => {
        if (response.success) {
          delete response.success;
          const payment: CustomerPayment = response;
          this.cart.payment = payment;
          this.intending = false;
          this.showPayment = true;
          requestAnimationFrame(() => this.initPaymentElement());
        } else {
          console.log("got error intending: ", response);
          this.intending = false;
        }
      });
    } catch (error) {
      this.intending = false;
      console.log('Error intending transaction: ', error);
    }
  }

  initPaymentElement() {
    const appearance = {
      theme: 'flat',
      variables: {
        colorPrimary: '#334573',
        colorBackground: '#ffffff',
        colorDanger: '#ffc3b4',
        fontFamily: 'span, sans-serif',
      },
      rules: {
        '.Label': {
          color: '#be9666',
        },
        '.Input': {
          color: '#303f66',
        },
      },
    };
    const fonts = [{
      cssSrc: "https://the-spiritual-garden.web.app/styles.css"
    }];
    const options = {
      clientSecret: this.cart?.payment?.paymentIntent,
      // Fully customizable with appearance API.
      appearance, fonts,
    };

    // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
    const elements = stripe.elements(options);

    // Create and mount the Payment Element
    const paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');

    const form = document.getElementById('payment-form')!;

    form.addEventListener('submit', async (event) => {
      event.preventDefault();
      this.errorMessage = "";
      this.loading = true;

      const {paymentIntent, error} = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: 'https://the-spiritual-garden.web.app/confirm/' + this.cart?.id,
        },
        redirect: 'if_required',
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        // const messageContainer = document.querySelector('#error-message')!;
        this.errorMessage = error.message;
        this.loading = false;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        // console.log("payment completed successfully: ", paymentIntent);
        if (paymentIntent.status === "succeeded") {
          this.orderReceived = true;
          try {
            this.cart.status = 'received';
            this.cart.createdAt = new Date().toJSON();
            // console.log("updating transaction status:", this.txn, this.uid);
            await this.orders.submitOrder(this.cart);
          } catch (error) {
            // console.log("there was an error updating txn: ", error);
          }
        }
      }
    });
  }

}
