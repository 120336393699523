import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import firebase from 'firebase/app';
// import 'firebase/firestore';
import { User, Credentials } from '../models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthController {

  private authUser: BehaviorSubject<firebase.User | firebase.auth.UserCredential>;
  public user: User;
  public userChanges: Observable<User[]>;
  uri = "https://us-central1-the-spiritual-garden.cloudfunctions.net";
  creatingCustomer = false;

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private http: HttpClient,
  ) {
    this.authUser = new BehaviorSubject<firebase.User>(JSON.parse(localStorage.getItem('fauthUser')));
    auth.authState.subscribe(user => this.setAuthorizedUser(user));
    this.user = this.authUser.value as User;
    if (this.user) this.setAuthorizedUser();
  }

  get info(): firebase.User | firebase.auth.UserCredential {
    return this.authUser.value;
  }

  get authenticated(): boolean {
    return this.info !== null;
  }

  setAuthorizedUser(user?: firebase.User | firebase.auth.UserCredential) {
    if (!user) (user as User) = this.user;
    localStorage.setItem('fauthUser', JSON.stringify(user));
    this.authUser.next(user);
    this.getUser(user);
    console.log('authenticated?', this.authenticated, this.authUser.value);
  }

  // signInWithEmail(credentials: Credentials): Promise<firebase.User | firebase.auth.UserCredential> {
  signInWithEmail(credentials: Credentials) {
    return this.auth.signInWithEmailAndPassword(credentials.email, credentials.password);
  }

  signInWithEmailLink(email: string): Promise<void | firebase.auth.UserCredential> {
    const link = window.location.href;
    if (this.auth.isSignInWithEmailLink(link)) {
      return this.auth.signInWithEmailLink(email, link);
    }
    return new Promise((resolve, reject) => reject());
  }

  signUp(credentials: Credentials): Promise<firebase.auth.UserCredential | User> {
    return this.auth.createUserWithEmailAndPassword(credentials.email, credentials.password);
  }

  storeUser(user: User): Promise<DocumentReference> {
    return this.firestore.collection<User>('users').add(user);
  }

  private getUser(user: any = this.authUser): void {
    if (user === null || user.uid === undefined) { return; }
    console.log('getting user by ID: ', user.uid);
    this.userChanges = this.firestore.collection<User>('users', ref => ref.where('uid', '==', user.uid)).valueChanges();
    this.userChanges.subscribe(u => {
      console.log('User info is: ', this.user = u[0]);
      if (this.user.cid == null && !this.creatingCustomer) {
        this.creatingCustomer = true;
        this.createCID(this.user);
      }
    });
  }

  createCustomer(details: {
    name: string;
    uid: string;
    email: string;
    phone: string;
    headline: string;
  }): Observable<any> {
    return this.http.post(`${this.uri}/createCustomer`, details);
  }

  createCID(user: User) {
    const member = {
      name: user.name ?? "Anonymous Member",
      uid: user.uid,
      email: user.email!,
      phone: user.phone ?? "",
      headline: user.headline,
    };
    this.createCustomer(member).subscribe(async (data) => {
      const {success, customer} = data;
      if (success) {
        user.cid = customer.id;
        await this.updateUser(user);
      }
    })
  }

  updateUser(user: User): Promise<void> {
    return this.firestore.collection<User>('users').doc(user.id).update(user);
  }

  qualifyUsername(qualifier: string): Observable<User[]> {
    return this.firestore.collection<User>('users', ref => ref.where('username', '==', qualifier.toLowerCase())).valueChanges();
  }

  async logout() {
    console.log('removing logged in credentials.');
    await this.auth.signOut();
    localStorage.removeItem('fauthUser');
    this.authUser.next(null);
  }

}
