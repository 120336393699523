import { Injectable } from "@angular/core";
import {
  DocumentSnapshot,
  DocumentReference,
  CollectionReference,
  AngularFirestore,
} from "@angular/fire/firestore";
import { Observable } from 'rxjs';
import { MasterSettings, UserSettings } from "../models";
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class SettingsController {

  db = "settings";
  master: MasterSettings = {key: ""};

  constructor(
    private firestore: AngularFirestore,
  ) {
    this.initMasterSettings();
  }

  async initMasterSettings() {
    this.firestore.collection<MasterSettings>(this.db).doc("master").get().subscribe((doc) => {
      if (doc.exists) {
        const settings = doc.data();
        console.log("Master Settings data: ", this.master = settings);
      }
    });
  }

  getSettings(id: string): Observable<firebase.firestore.DocumentSnapshot<UserSettings>> {
    return this.firestore.collection<UserSettings>(this.db).doc(id).get();
  }

  updateSettings(set: UserSettings, id: string): Promise<void> {
    return this.firestore.collection<UserSettings>(this.db).doc(id).set(set);
  }

}
