// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripeKey: 'pk_test_51OaQQpLUKyQ66J4lHBVx7l5RGWNNzHW9exJ4znFgxif75mvBM0xKtQuR8NGQNSYG5YH6u7aYzv8iXQ8Kc1taEv8b00U9Ey9ZbG',
  firebase: {
    apiKey: "AIzaSyCMmrely51WVRJIkaaLFw-2JrnDqpeuFrc",
    authDomain: "the-spiritual-garden.firebaseapp.com",
    projectId: "the-spiritual-garden",
    storageBucket: "the-spiritual-garden.appspot.com",
    messagingSenderId: "248070844970",
    appId: "1:248070844970:web:f8798faa53b7ee0dab8953",
    measurementId: "G-CV5WJF0JVV"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
