import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomerAmount, CustomerPayment } from "../models";

@Injectable({
  providedIn: 'root'
})
export class PaymentController {
  uri = "https://us-central1-the-spiritual-garden.cloudfunctions.net";

  constructor(private http: HttpClient) {}

  addCredit(secureAmount: CustomerAmount): Observable<any> {
    return this.http.post(`${this.uri}/addCredit`, secureAmount);
  }

  createCard(details: {cid: string, token: string}): Observable<any> {
    return this.http.post(`${this.uri}/addCard`, details);
  }

  createPayoutCard(details: {acct: string, token: string}): Observable<any> {
    return this.http.post(`${this.uri}/addPayoutCard`, details);
  }

  cancelPayment(payment: CustomerPayment) {
    return this.http.post(`${this.uri}/cancelPayment`, payment);
  }

}
