import { Injectable } from "@angular/core";
import {
  DocumentSnapshot,
  DocumentReference,
  CollectionReference,
  AngularFirestore,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Event } from "../models";
import { AngularFireStorage } from "@angular/fire/storage";
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class EventController {

  db = "events";

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  getEvents(uid: string): Observable<Event[]> {
    return this.firestore.collection<Event>(this.db, ref =>
      ref.where('uid', '==', uid)).valueChanges({ idField: 'id' });
  }

  getEvent(id: string): Observable<firebase.firestore.DocumentSnapshot<Event>> {
    return this.firestore.collection<Event>(this.db).doc(id).get();
  }

  getEventsByCategory(category: string) {
    return this.firestore.collection<Event>(this.db, ref =>
      ref.where('category', '==', category)).valueChanges({ idField: 'id' });
  }

  createEvent(event: Event): Promise<DocumentReference<Event>> {
    return this.firestore.collection<Event>(this.db).add(event);
  }

  updateEvent(event: Event): Promise<void> {
    return this.firestore.collection<Event>(this.db).doc(event.id).update(event);
  }

  uploadFeaturedImage(image: File, name: string) {
    return this.storage.upload(`images/event-featured/${name}`, image);
  }

  deleteImage(downloadUrl: string) {
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }

}
