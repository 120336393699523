import { Injectable } from "@angular/core";
import {
  DocumentSnapshot,
  DocumentReference,
  CollectionReference,
  AngularFirestore,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { User, UserSettings } from "../models";

@Injectable({
  providedIn: 'root'
})
export class UserController {

  db = "users";

  constructor(
    private firestore: AngularFirestore,
  ) { }

  getUsers(): Observable<User[]> {
    return this.firestore.collection<User>(this.db).valueChanges();
  }

  getMember(uid: string): Observable<User[]> {
    return this.firestore.collection<User>(this.db, ref =>
      ref.where('uid', '==', uid)).valueChanges();
  }

  getMemberName(uid: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.firestore.collection<User>(this.db, ref =>
        ref.where('uid', '==', uid)).get().subscribe(docs => {
          const {name} = docs.docs[0].data();;
          resolve((name || "").trim() || 'Anonymous Member');
        }, error => reject(error));
    });
  }

  getMemberByCID(cid: string): Observable<User[]> {
    return this.firestore.collection<User>(this.db, ref =>
      ref.where('cid', '==', cid)).valueChanges();
  }

  getMemberByAcct(acct: string): Observable<User[]> {
    return this.firestore.collection<User>(this.db, ref =>
      ref.where('acct', '==', acct)).valueChanges();
  }

  updateMember(member: User): Promise<void> {
    return this.firestore.collection<User>(this.db).doc(member.id).update(member);
  }

}
