import { Injectable } from "@angular/core";
import {
  DocumentSnapshot,
  DocumentReference,
  CollectionReference,
  AngularFirestore,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { MemberTransaction } from "../models";
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class TransactionController {

  db = "transactions";

  constructor(
    private firestore: AngularFirestore,
  ) { }

  getChain(uid: string): Observable<MemberTransaction[]> {
    return this.firestore.collection<MemberTransaction>(this.db, ref =>
      ref.where('chain', '==', uid).orderBy("createdAt")).valueChanges({ idField: "id" })
  }

  getTxn(id: string): Observable<firebase.firestore.DocumentSnapshot<MemberTransaction>> {
    return this.firestore.collection<MemberTransaction>(this.db).doc(id).get();
  }

  storeTxn(txn: MemberTransaction): Promise<void> {
    return this.firestore.collection<MemberTransaction>(this.db).doc(txn.id).set(txn);
  }

  updateTxn(txn: MemberTransaction, id: string): Promise<void> {
    return this.firestore.collection<MemberTransaction>(this.db).doc(txn.id).update(txn);
  }
  
  removeTxn(txn: MemberTransaction): Promise<void>  {
    return this.firestore.collection<MemberTransaction>(this.db).doc(txn.id).delete();
  }

}
