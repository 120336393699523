import { Injectable } from "@angular/core";
import {
  DocumentSnapshot,
  DocumentReference,
  CollectionReference,
  AngularFirestore,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { User, UserSettings } from "../models";

@Injectable({
  providedIn: 'root'
})
export class AccountController {

  activeNav: string;

}
