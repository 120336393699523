import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthController } from './auth.controller';
import { Order } from '../models';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class OrderController {

  private localBasket: BehaviorSubject<Order[]>;
  private localOrders: BehaviorSubject<Order[]>;
  private todaysOrders: BehaviorSubject<Order[]>;
  public todaysOrdersObs: Observable<Order[]>;

  constructor(
    private firestore: AngularFirestore,
    private auth: AuthController,
  ) {
    this.localBasket = new BehaviorSubject<Order[]>([JSON.parse(localStorage.getItem('tsgBasket'))]);
    this.localOrders = new BehaviorSubject<Order[]>(JSON.parse(localStorage.getItem('tsgOrders')));
    this.todaysOrders = new BehaviorSubject<Order[]>([]);

    this.todaysOrdersObs = this.firestore.collection<Order>('orders',
      ref => ref.where('createdAt', '>=', new Date(new Date().toLocaleDateString()).toJSON())).valueChanges();
    this.todaysOrdersObs.subscribe(orders => (this.todaysOrders.next(orders.map((o: any) => o.number)),
      console.log('Orders made today: ', this.todaysOrders.value, orders)));
  }

  get itemsInBasket(): number {
    return this.localBasket.value ?
      this.localBasket.value[0] !== null ? this.localBasket.value[0].products.length : 0 : 0;
  }

  get today(): number {
    return this.todaysOrders.value && this.todaysOrders.value.length || 0;
  }

  createCart(order: Order, removeLocalBasket?: boolean) {
    if (this.auth.authenticated) {
      if (removeLocalBasket) localStorage.removeItem('tsgBasket'), this.localBasket.next(null);
      return this.createOrder(order);
    } else {
      return new Promise(resolve => {
        localStorage.setItem('tsgBasket', JSON.stringify(order));
        this.localBasket.next([order]), resolve(true);
      });
    }
  }

  getCart(): Observable<Order[]> {
    if (this.auth.user) {
      console.log("getting authorized cart.")
      return this.firestore.collection<Order>('orders', ref =>
        ref.where('client.uid', '==', this.auth.user.uid).where('status', '==', 'drafted')).valueChanges({ idField: 'id' });
    } else {
      return of(this.localBasket.value);
    }
  }

  updateCart(order: Order) {
    if (this.auth.authenticated) {
      console.log("updating order: ", order);
      return this.firestore.collection<Order>('orders').doc(order.id).update(order);
    } else {
      return new Promise(resolve => {
        localStorage.setItem('tsgBasket', JSON.stringify(order));
        this.localBasket.next([order]), resolve(true);
      });
    }
  }

  createOrder(order: Order): Promise<any> {
    return this.firestore.collection<Order>('orders').add(order)
  }
  
  getOrder(orderId: string): Observable<firebase.firestore.DocumentSnapshot<Order>> {
    return this.firestore.collection<Order>('orders').doc(orderId).get();
  }

  getOrderByNumber(orderNumber: string): Observable<Order[]> {
    if (this.auth.authenticated) {
      return this.firestore.collection<Order>('orders', ref =>
        ref.where('number', '==', orderNumber)).valueChanges({ idField: 'id' });
    } else {
      return of([this.localOrders.value && this.localOrders.value.find(order => order.number === orderNumber)]);
    }
  }

  getOrders(uid: string = this.auth.user && this.auth.user.uid): Observable<Order[]> {
    if (this.auth.authenticated) {
      return this.firestore.collection<Order>('orders', ref =>
        ref.where('client.uid', '==', uid).orderBy('createdAt', 'desc')).valueChanges({ idField: 'id' });
    } else {
      return of(this.localOrders.value);
    }
  }

  saveStalledOrder(order: Order): Promise<any> {
    if (this.auth.authenticated) {
      return this.submitOrder(order);
    } else {
      const orders = this.localOrders.value || [];
      const stalled = orders.find(o => o.createdAt === order.createdAt || o.number === order.number);
      if (stalled) { stalled.status = 'created', stalled.number = order.number;
      } else { orders.push(order); }
      localStorage.setItem('tsgOrders', JSON.stringify(orders));
      this.localOrders.next(orders), this.localBasket.next([null]);
      return new Promise(resolve => {
        resolve(this.createOrder(order));
      });
    }
  }

  submitOrder(order: Order): Promise<any> {
    order.orderType = this.auth.authenticated ? 'client' : 'public';
    if (this.auth.authenticated) {
      return this.firestore.collection<Order>('orders').doc(order.id).update(order);
    } else {
      const orders = this.localOrders.value || [];
      orders.push(order);
      console.log('local order submitted: ', order, orders);
      localStorage.setItem('tsgOrders', JSON.stringify(orders));
      localStorage.setItem('tsgBasket', null);
      this.localOrders.next(orders), this.localBasket.next([null]);
      return new Promise((resolve: any) => {
        resolve(this.createOrder(order));
      });
    }
  }

  stallOrder(order: Order): Promise<any> {
    order.status = 'stalled';
    const orders = this.localOrders.value || [];
    const stalled = orders.find(o => o.createdAt === order.createdAt || o.number === order.number);
    if (stalled) { stalled.status = 'stalled';
    } else { orders.push(order); }
    localStorage.setItem('tsgOrders', JSON.stringify(orders)), this.localOrders.next(orders);
    if (this.auth.authenticated) {
      return this.firestore.collection<Order>('orders').doc(order.id).update(order);
    } else {
      return new Promise(resolve => resolve(true));
    }
  }

  searchReasonStalled(order: Order) {
    return new Promise(resolve => {
      let reason: string;
      const sub = this.firestore.collection<Order>('orders', ref =>
          ref.where('number', '==', order.number)).valueChanges().subscribe(o => {
        reason = o.length ? o[0].reasonStalled || 'unspecified' : 'not-created';
        resolve(reason), sub.unsubscribe();
      });
    });
  }

  updateOrder(order: Order) {
    if (this.auth.authenticated) {
      return this.firestore.collection<Order>('orders').doc(order.id).update(order);
    } else {
      const orders = this.localOrders.value || [];
      const update = orders.find(o => o.createdAt === order.createdAt || o.number === order.number);
      if (update) { Object.assign(update, order); }
      localStorage.setItem('tsgOrders', JSON.stringify(orders)), this.localOrders.next(orders);
      return new Promise(resolve => {
        resolve(this.firestore.collection<Order>('orders').doc(order.id).update(order));
      });
    }
  }

}
