import { Component, OnInit } from '@angular/core';
import { AuthController, OrderController } from 'src/app/controllers';
import { Order } from 'src/app/models';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  isCollapsed = true;

  cart: Order;

  get user() {
    return this.auth.user;
  }

  get cartCount(): number {
    if (this.auth.authenticated) {
      return this.cart ? this.cart.products.length : 0;
    } else {
      return this.orders.itemsInBasket;
    }
  }

  constructor(
    private auth: AuthController,
    private orders: OrderController,
  ) { }

  ngOnInit(): void {
    this.orders.getCart().subscribe(orders => {
      this.cart = orders[0];
      console.log("got cart: ", this.cart, orders);
    });
  }

  commalize(n: any) {
    if (!n) { return '0.00'; }
    const digits = (Number(n)).toFixed(2).split('').reverse();
    const decimals = digits.splice(0, 3);
    for (let i = 0, l = digits.length; i < l; i++) { if (i > 0 && i % 3 === 0) { digits[i] += ','; } }
    return decimals.concat(digits).reverse().join('');
  }

}
