import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map as m } from 'rxjs/operators';
import { Product } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProductController {

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) { }

  getProducts(): Observable<Product[]> {
    return this.firestore.collection<Product>('products', ref => ref.orderBy('slug', 'asc')).valueChanges({ idField: 'id' });
  }

  getProduct(slug: string) {
    return this.firestore.collection<Product>('products', ref => ref.where('slug', '==', slug)).valueChanges({ idField: 'id' });
  }

  getProductsByCategory(category: string) {
    return this.firestore.collection<Product>('products', ref => ref.where('category', '==', category)).valueChanges({ idField: 'id' });
  }

  validateProduct(slug: string) {
    return this.firestore.collection<Product>('products', ref => ref.where('slug', '==', slug)).get();
  }

  addProduct(product: Product) {
    return this.firestore.collection<Product>('products').add(product);
  }

  updateProduct(product: Product) {
    return this.firestore.collection<Product>('products').doc(product.id).update(product);
  }

  uploadImage(product: string, image: File, name: string) {
    return this.storage.upload(`images/product-images/${product}/${name}`, image);
  }

  uploadPoster(image: File, name: string) {
    return this.storage.upload('images/product-poster/' + name, image);
  }

  deleteImage(downloadUrl) {
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }

}
